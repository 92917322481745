@import '@angular/material/prebuilt-themes/indigo-pink.css'; // important for feedback module to work
@import "node_modules/@aecom/core/src/assets/styles/core.scss";

@page {
  margin: 0;
  size: 612pt 792pt;
}

body {
  background-color: white;
  font-family: 'urw-din-semi-condensed', 'Barlow Semi Condensed', sans-serif;
}

a {
  text-decoration: none !important;
}

#sidebarwrapper {
  position: fixed;
  z-index: 2;
  left: 0px;
  top: 40px;
  bottom: 0;
  // width:550px;
  //height:100%;
  //margin-top:40px;
  background-color: #111111;
}

.app-left-nav .wrapper {
  width: 100%;
  overflow: hidden;
}

.appLogo {
  mask: url('../icons/appLogo.svg') no-repeat 50% 50%;
  background-color: #fff;
  width: 159px;
  height: 16px;
}

.browser .node.root-node,
.nav-browser .children .child-node {
  text-transform: capitalize;
}

.app-left-nav,
.app-header .button.show,
.app-header .button:hover {
  background-color: #111;
}

.sandboxHeader .app-header {
  background-color: red !important;
}

.bbj_onboarding_icon {
  background-color: #00b5e2;
  mask: url("../icons/bbj_onboarding_icon.svg") no-repeat 50% 50%;
}

.bbj_product_icon_settings {
  background-color: #BE2150;
  mask: url("../icons/bbj_product_icon_settings.svg") no-repeat 50% 50%;
}
.icon-action_app_support {
  background-color: #00b5e2;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: white;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 5px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b5e2; 
  border-radius: 10px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #008db0; 
}